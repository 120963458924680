import * as React from "react"
import { IconMail2, IconPhone, IconPlace } from "../icons/Icons"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const HaveQuestions = ({ className, heading }) => {
  return (
    <section
      name="Still Have Questions?"
      className={className || "container mx-auto mt-16 flex flex-wrap px-4 md:p-0"}
    >
      <div className={"w-full"}>
        <h2 className={"text-center text-4xl uppercase"}>{heading || "Still have Questions?"}</h2>
      </div>
      <div className={"w-full mt-16 lg:mb-16"}>
        <p className={"mx-auto w-full text-center text-lg lg:w-1/2 lg:text-xl 2xl:w-2/5"}>
          Our highly trained <Link to={"/about-us/"}>specialists</Link>, coupled with our
          international sales reach, allow us to{" "}
          <Link to={"/what-is-my-jewelry-worth/"}>offer</Link> the very best prices for your
          jewelry.
        </p>
      </div>
      <div className={"flex w-full flex-wrap gap-8 xl:gap-16 lg:flex-nowrap"}>
        <a
          href={
            "https://www.google.com/maps/search/?api=1&query=9595%20Harding%20Ave%2C%20Surfside%2C%20FL%2C%2033154&ll=Gray%20&%20Sons"
          }
          target={"_blank"}
          rel={"noreferrer"}
          className={
            "align-center group flex w-full flex-col justify-center py-4 text-center lg:w-1/3"
          }
        >
          <div className={"lg:mb-8 text-5xl text-accent"}>
            <IconPlace />
          </div>
          <div className={"text-3xl text-black group-hover:text-link uppercase"}>Visit Us</div>
          <div className={"lg:mt-16 text-link lg:h-20"}>
            9595 Harding Avenue, Bal Harbour Florida 33154
          </div>
        </a>
        <a
          href={"tel:+13057706955"}
          className={
            "align-center group flex w-full flex-col justify-center lg:border-l lg:border-r border-accent text-center lg:w-1/3"
          }
        >
          <div className={"lg:mb-8 text-5xl text-accent"}>
            <IconPhone />
          </div>
          <div className={"text-3xl text-black group-hover:text-link uppercase"}>Call Us</div>
          <div className={"lg:mt-16 text-accent lg:h-20"}>tel: 305 770 6955</div>
        </a>
        <a
          href={"mailto:sell@grayandsons.com"}
          className={"align-center group flex w-full flex-col justify-center text-center lg:w-1/3"}
        >
          <div className={"lg:mb-8 text-5xl text-accent"}>
            <IconMail2 />
          </div>
          <div className={"text-3xl text-black group-hover:text-link uppercase"}>Email Us</div>
          <div className={"lg:mt-16 text-accent lg:h-20"}>sell@grayandsons.com</div>
        </a>
      </div>
    </section>
  )
}
HaveQuestions.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
}

export default HaveQuestions
